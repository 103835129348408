import React, { useRef, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import styled from "styled-components"

import pdfFile from '../../res/Resume-JiayuanChen.pdf';

export default function Resume() {
    useEffect(() => {
        aRef.current.click();
    });

    const aRef = useRef(null);

    return (
        <a href={pdfFile} style={{display:'none'}} ref={aRef}>asdfas</a>
    );
}