import React, { useRef } from 'react'
import styled from 'styled-components'
import { CJY_WHITE } from "../../Config"
import wechatUrl from '../../res/wechat.jpg'
import './Foot.css'

export default function Foot(){
    const wechatRef = useRef(null);
    const mailRef = useRef(null);
    const githubRef = useRef(null);
    const modRef = useRef(null);
    const linkedinRef = useRef(null);
    const lcRef = useRef(null);
    const refs = [wechatRef, mailRef, githubRef, linkedinRef, lcRef, modRef];

    const imgRef = useRef(null);

    let expandtime = 0;

    const wechatEnter = () => {
        wechatRef.current.classList.toggle('is-wechat-open');
        wechatRef.current.classList.toggle('mouse-on');
        let tm = setInterval(() => {
            expandtime += 25;
            if (wechatRef.current.clientHeight === 200) {
                imgRef.current.classList.toggle('wechat-img-show');
                clearInterval(tm);
            }
        }, 25)
    }

    const wechatLeave = () => {
        imgRef.current.classList.toggle('wechat-img-show');
        setTimeout(() => {
            wechatRef.current.classList.toggle('is-wechat-open');
            wechatRef.current.classList.toggle('mouse-on');
        }, expandtime)
        expandtime = 0;
    }

    const normalEnter = (num) => {
        refs[num].current.classList.toggle('mouse-on');
    }

    const normalLeave = (num) => {
        refs[num].current.classList.toggle('mouse-on');
    }

    return(
        <div>
            <div className="contactInfo" style={{position:"fixed", bottom: '0', width: '100vw', display: "flex", alignItems: 'flex-end'}}>
                <div style = {{userSelect:'none'}} onMouseEnter={wechatEnter} onMouseLeave={wechatLeave} ref={wechatRef} className='wechat-box foot-box'>
                    <div style={{height: '35px', display:'flex',
                        alignItems:'center', justifyContent:'center', flexGrow:'0'}}>
                        Wechat: geraintcjy
                    </div>
                    <div id='wechatImage' className='wechat-img' ref={imgRef}>
                        <img src={wechatUrl} width='150' height='150' alt='wechat QR code' />
                    </div>
                </div>

                <div onMouseEnter={() => normalEnter(1)} onMouseLeave={() => normalLeave(1)}
                     ref={mailRef} className='foot-box'>
                    <a
                        href = "https://mail.google.com/mail/u/0/#inbox?compose=CllgCJvrcXdVlSghTjvCVBFbmSmnCSRtJsVQqZzpWcfXwkCKQvnLQDgcSZQvdglZZGJLHhXMkGB"
                        style = {{textDecoration: 'none', color: CJY_WHITE}}
                        target = "_blank"
                        rel="noreferrer">Mail</a>
                </div>

                <div onMouseEnter={() => normalEnter(2)} onMouseLeave={() => normalLeave(2)}
                     ref={githubRef} className='foot-box'>
                    <a
                        href = "https://github.com/geraintcjy"
                        style = {{textDecoration: 'none', color: CJY_WHITE}}
                        target = "_blank"
                        rel="noreferrer">Github</a>
                </div>

                <div onMouseEnter={() => normalEnter(3)} onMouseLeave={() => normalLeave(3)}
                     ref={linkedinRef} className='foot-box'>
                    <a
                        href = "https://www.linkedin.com/in/geraintcjy"
                        style = {{textDecoration: 'none', color: CJY_WHITE}}
                        target = "_blank"
                        rel="noreferrer">Linkedin</a>
                </div>

                <div onMouseEnter={() => normalEnter(4)} onMouseLeave={() => normalLeave(4)}
                     ref={lcRef} className='foot-box'>
                    <a
                        href = "https://leetcode.com/geraintcjy/"
                        style = {{textDecoration: 'none', color: CJY_WHITE}}
                        target = "_blank"
                        rel="noreferrer">Leetcode</a>
                </div>

                <div style = {{userSelect: 'none'}} onMouseEnter={() => normalEnter(5)} onMouseLeave={() => normalLeave(5)}
                     ref={modRef} className='foot-box'>
                    Last Updated: May, 2022
                </div>

                {/*
                    <div style = {{flexGrow: 0.8}} onMouseEnter={normalEnter} onMouseLeave={normalLeave} className='foot-box'>
                        <a
                            href = "https://beian.miit.gov.cn/"
                            style = {{textDecoration: 'none', color: '#F2F2F2'}}
                            target = "_blank"
                            rel="noreferrer">沪ICP备2021009744号</a>
                    </div>
                    */}
            </div>

            <div id='wechatImage' style={{position:"fixed", bottom: '4vh', height: '200px', width: '200px',
                backgroundColor: "#555555", display: 'none', opacity: 0}}>
                <img src="wechat.jpg" alt='wechat QR code' />
            </div>
        </div>
    )
}
