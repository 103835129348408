import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import Submitted from './pages/Submitted/Submitted'
import Resume from './pages/Resume/Resume'


export const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path = "/" render={()=><Redirect to="/home" />} />
                <Route path = "/home" component = {Home} />
                <Route path = "/submit" component = {Submitted} />
                <Route path = "/doc/resume" component = {Resume} />
            </Switch>
        </BrowserRouter>
    );
}