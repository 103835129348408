import React, { useRef, useEffect } from 'react'
import { isPC } from "./Home"
import { replaceBreakWithPTag } from "../../util"
import styled from "styled-components"

const intro = "Hi there, thanks for visiting! My name is Jiayuan (Jah-Yuan) Chen and I'm a CSE Masters student at Georgia Tech.\n" +
    "I'm currently in my first year of study and am now looking for this year's fall software engineering (preferably in backend or mobile) co-op opportunities. " +
    "My contact info can be found at the bottom of the page. No car insurance service needed as I don't have one :) \n" +
    "You can get my resume from";

export default function Description() {
    const introRef = useRef(null);

    useEffect(() => {
        introRef.current.innerHTML = htmlIntro + introRef.current.innerHTML;
    })

    const htmlIntro = replaceBreakWithPTag(intro);

    return (
        <div>
            <Text isPC = { isPC() }>
                <span ref={introRef} >
                    {' '}
                    <a style = {{display: "inline-block", fontWeight: 'bold'}}
                       href = "/doc/resume">
                        here
                    </a>
                    {'.'}
                </span>
            </Text>
        </div>
    )
}

const Text = styled.div`
  text-align: justify;
  width: ${props => {return props.isPC ? '100%' : '100%'}};
  margin: 0 auto 20px auto;
  font-size: 16px;
  line-height: 30px;
  font-family: Comic Sans MS, Comic Sans, cursive;
  -webkit-user-select: none;
  -moz-user-select:none;
  -ms-user-select:none;
`
